import React, { useContext } from 'react';
import flush from 'just-flush';
import { IconDetailsGrid } from '@stereoagency/ui';
import type { ILazyBlocksImage } from '@/types';
import { useCallToActions, useMediaItems } from '@/hooks';
import { LinesContext } from '@/components/LinesContext';
import { parseHtml } from '@/utils';

type CardEntry = {
  card_icon?: ILazyBlocksImage;
  card_title?: string;
  card_description?: string;
  card_cta?: number;
};

type Props = {
  data: {
    label?: string;
    title?: string;
    cards?: CardEntry[]
    disable_card_title_borders?: boolean
    darkbackground?: boolean
  }
};

export default function NvisoIconDetailsGrid({ data }: Props) {
  const { enableLines } = useContext(LinesContext);
  const callToActionIds = Array.from(data.cards?.reduce<Set<number>>((acc, card) => {
    if (typeof card.card_cta === 'number') { acc.add(card.card_cta); }
    return acc;
  }, new Set()) ?? new Set<number>());
  const mediaItemIds = Array.from(data.cards?.reduce<Set<number>>((acc, card) => {
    if (typeof card.card_icon?.id === 'number') { acc.add(card.card_icon.id); }
    return acc;
  }, new Set()) ?? new Set<number>());
  const callToActionObjects = useCallToActions(callToActionIds, true);
  const mediaItems = useMediaItems(mediaItemIds);
  const entries = flush(data.cards?.map((card) => {
    const icon = typeof card.card_icon?.id === 'number' ? mediaItems.get(card.card_icon.id) : null;
    const cta = typeof card.card_cta === 'number' ? callToActionObjects.get(card.card_cta) : null;
    if (!icon?.mediaItemUrl || !card.card_title) return null;
    return {
      icon: {
        src: icon.mediaItemUrl,
        width: icon.mediaDetails?.width ?? 140,
        height: icon.mediaDetails?.height ?? 140,
      },
      title: card.card_title,
      description: card.card_description ? parseHtml(card.card_description) : null,
      callToAction: cta ?? undefined,
      noTitleBorder: !!data.disable_card_title_borders,
    };
  }) ?? []);

  if (!data.title) {
    return null;
  }

  return (
    <IconDetailsGrid
      label={data.label}
      title={data.title}
      cards={entries}
      enableLines={enableLines}
      darkbackground={data.darkbackground}
    />
  );
}
